import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "toast",
      "style": {
        "position": "relative"
      }
    }}>{`Toast`}</h1>
    <p>{`Utilize `}<em parentName="p">{`toasts`}</em>{` para mostrar informações que requerem mínima interação do usuário. As mensagens desaparecem após 5 segundos ou podem ser fechadas manualmente e são apresentadas nos cantos inferiores da tela. As mensagens não podem atrapalhar o fluxo de trabalho do usuário. São comumente utilizadas para alertar sobre `}<em parentName="p">{`status`}</em>{` de tarefas.`}</p>
    <h2 {...{
      "id": "boas-praticas",
      "style": {
        "position": "relative"
      }
    }}>{`Boas práticas`}</h2>
    <ul>
      <li parentName="ul">{`Mensagens devem limitar seu corpo de texto em no máximo duas linhas.`}</li>
      <li parentName="ul">{`Devem desaparecer automaticamente após 5 segundos, e devem ter um ícone de fechar. Se entrar no `}<em parentName="li">{`status`}</em>{` de `}<em parentName="li">{`hover`}</em>{`, a `}<em parentName="li">{`toast`}</em>{` deve ser mantida aberta.`}</li>
      <li parentName="ul">{`Podem ser incluídos links que redirecionam o usuário para próximos passos.`}</li>
      <li parentName="ul">{`Utilize ícones para transmitir clareza adicional. Os ícones informativos devem ser posicionados à esquerda do título.`}</li>
    </ul>
    <p><strong parentName="p">{`Este componente ainda não foi implementado como um componente em React.`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      